import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import photo from '../assets/images/PHOTO.jpg'; // Correct import

const About: React.FC = () => {
  const navigate = useNavigate();
  const handleReadMore = () => {
    navigate('/details');
  };

  return (
    <section 
      id="about" 
      className="py-8 md:py-12 bg-gray-100" // Use min-h-screen for full height section
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center md:items-start md:space-x-8">
          <motion.div
            className="md:w-1/2 w-full"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-4 text-green-700 text-center md:text-left">
              About Us
            </h2>

            <p className="text-base md:text-lg text-justify mb-6">
              Navayuga Education and Rural Development Society (R), registered under the Society’s Act of 1960 in 2016, is dedicated to empowering women and driving sustainable development in rural communities. We prioritize supporting marginalized groups, particularly women, by providing the tools and resources necessary for success. Our key initiatives include forming Self Help Groups (SHGs) to foster economic empowerment and financial independence among women. We also emphasize improving access to clean drinking water, managing groundwater resources, and addressing sewage and sanitation issues, in partnership with UNICEF. Additionally, we support small farmers with subsidized farm machinery to boost productivity and entrepreneurship. Our work extends to vital public health issues like family welfare, population control, and food safety, while enhancing educational institutions for better learning environments. Environmental conservation is central to our mission, with projects focused on regenerative agriculture, reforestation, and climate justice. We also promote healthy living through nutrition education, guiding communities on proper food choices and preparation to improve overall well-being.
            </p>

            <motion.button 
              className='bg-yellow-200 text-black px-4 py-2 md:px-5 md:py-3 rounded-md border-2 mx-auto md:mx-0 block'
              onClick={handleReadMore}
            >
              READ MORE
            </motion.button>
          </motion.div>

          <motion.div
            className="md:w-1/2 w-full mt-8 md:mt-0 flex justify-center items-center h-full"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img
              className="w-full h-full object-cover rounded-lg shadow-lg"
              src={photo} 
              alt="Description of the image"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;