import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link, scroller } from 'react-scroll';
import { logo } from '../assets/images';

const Navbar: React.FC = () => {
  const location = useLocation();
  const showNavbar = location.pathname.startsWith('/programs/');

  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate("/contact")
  }




  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogoClick = () => {
    scroller.scrollTo('home', {
      smooth: true,
      duration: 500,
    });
  };

  return (
    <nav
      className={`fixed w-full z-50 top-0 transition-all duration-300 ${scrolled ? 'bg-transparent' : 'bg-transparent'}`}
    >
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Enhanced Logo on the left */}
        <div className="flex items-center cursor-pointer" onClick={handleLogoClick}>
          <div className="relative">
            <img
              src={logo}
              alt="Logo"
              className="w-12 h-12 md:w-24 md:h-24 rounded-full shadow-xl hover:scale-110 transition-transform duration-300 border-4 border-yellow-200"
            />
          </div>
          <div className="ml-4 flex flex-col items-start hidden md:flex">
            <div className="w-full h-1 mt-1 bg-blue-800 rounded"></div>
          </div>
        </div>

        {/* Links on the right with green stripe and reverse border curve */}
        <div className="hidden md:flex space-x-6 items-center relative">
          <div className="bg-yellow-200 rounded-l-[50px] rounded-r-[10px] pl-4 pr-6 py-2 flex items-center shadow-md">
            {['home', 'about', 'programs', 'Get Involved'].map((item, index) => (
              <Link
                key={index}
                to={item}
                smooth={true}
                duration={500}
                className={`${
                  scrolled ? 'text-green-900 hover:text-green-700' : 'text-gray-900 hover:text-gray-700'
                } font-semibold uppercase tracking-wider transition-colors duration-200 ml-4`}
              >
                {item.charAt(0).toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1')}
              </Link>
            ))}
            <button className="bg-gray-500 text-white px-6 py-2 rounded-lg shadow-md font-bold uppercase hover:bg-yellow-600 transition-colors duration-200 ml-4" onClick={handleOnClick}>
              Contact
            </button>
          </div>
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-3xl text-white focus:outline-none">
            <div className={`relative w-8 h-8 flex flex-col justify-center items-center ${isOpen ? 'text-black' : 'text-black'}`}>
              <div className={`w-8 h-1 bg-current transition-transform duration-300 ${isOpen ? 'rotate-45 translate-y-2' : ''}`}></div>
              <div className={`w-8 h-1 bg-current my-1 transition-opacity duration-300 ${isOpen ? 'opacity-0' : ''}`}></div>
              <div className={`w-8 h-1 bg-current transition-transform duration-300 ${isOpen ? '-rotate-45 -translate-y-2' : ''}`}></div>
            </div>
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu with Animation */}
      {isOpen && (
        <div className="fixed inset-0 bg-white z-40 transform transition-transform duration-300 ease-in-out"
          style={{ transform: isOpen ? 'translateX(0)' : 'translateX(-100%)' }}
        >
          <div className="relative flex flex-col h-full">
            {/* Close Button */}
            <button
              onClick={toggleMenu}
              className="absolute top-4 right-4 text-2xl text-black focus:outline-none"
            >
              <div className="w-8 h-1 bg-black transform rotate-45 translate-y-1"></div>
              <div className="w-8 h-1 bg-black transform -rotate-45 -translate-y-1"></div>
            </button>
            {/* Navbar Heading */}
            <h2 className="text-2xl font-bold text-center mt-12 mb-4 text-green-700">NAVAYUGA</h2>
            {/* Menu Items */}
            <div className="flex flex-col px-4 space-y-4">
              {['home', 'about', 'programs', 'vision-mission', 'getinvolved', 'contact'].map((item, index) => (
                <Link
                  key={index}
                  to={item}
                  smooth={true}
                  duration={500}
                  className="text-black font-semibold uppercase text-lg"
                  onClick={toggleMenu}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1')}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;