import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Programs from './pages/Programs';
import ProgramDetails from './pages/ProgramDetails';
import Contact from './pages/Contact';
import GetInvolved from './pages/GetInvolved';
import Footer from './pages/Footer';
import AboutDetails from './pages/AboutDetails';
import ContactUs from './pages/ContactUs';
import VisionMissionValues from './pages/VisionMissionValues';
import Team from './pages/Team';
import  Testimonials from './pages/Testimonials';


const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={
              <>
                <section id="home">
                  <Home />
                </section>
                <section id="about">
                  <About />
                </section>
                <section id="programs">
                  <Programs />
                </section>
                <section id="mission">
                  <VisionMissionValues />
                </section>
                <section id="testimonialPage">
                  <Testimonials />
                </section>
                <section id="getinvolved">
                  <GetInvolved />
                </section>
                <section id="join">
                  <Contact />
                </section>
                <section id="team">
                  <Team /> 
                </section>
              </>
            } />
            <Route path="/programs/:id" element={<ProgramDetails />} />
            <Route path="/details" element={<AboutDetails/>}/>
            <Route path="/contact" element={<ContactUs/>}/>
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
