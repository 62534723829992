import React from 'react';
import { motion } from 'framer-motion';
import veeresh from '../assets/images/veeresh.jpg';
import homeImage2 from '../assets/images/https___www.imagesofempowerment.org_wp-content_uploads_Hewlett_India_FullRelease_004-scaled.jpg';
import canara from '../assets/images/canara.png';
import kar from '../assets/images/kar.png';
import vidya from '../assets/images/PHOTO-2024-08-30-15-46-15.jpg';

const testimonials = [
  {
    image: veeresh,
    name: "Veeresh V.G",
    position: "Secretary at Sadhana",
    feedback: "Wishing Navayuga Education and Rural Development Society the very best as you embark on this new journey. Your NGO has immense potential, and I have no doubt that you will achieve great things. Keep up the incredible work and continue making a positive impact on the community.",
  },
  {
    image: vidya,
    name: "Vidya C.",
    position: "NGO Consultant, Social Activist",
    feedback: "As a guiding light for Navayuga, I've shared insights on starting and growing an NGO, helping the team avoid pitfalls and improve continuously. It's been rewarding to see these efforts contribute to Navayuga's success.",
  },
  {
    image: canara,
    name: "Canara Bank",
    position: "Banking Partner",
    feedback: "Canara Bank has played a pivotal role in supporting our Self-Help Groups (SHGs) and driving women empowerment in the communities we serve. Their commitment to financial inclusion and support has been invaluable to our mission.",
  },
  {
    image: kar,
    name: "Government of Karnataka",
    position: "State Government",
    feedback: "The Government of Karnataka has been a steadfast partner in our efforts to uplift communities across the state. Their support for our NGO’s initiatives has significantly contributed to our success in addressing key social challenges.",
  },
  {
    image: homeImage2,
    name: "Lakshmi",
    position: "SHG Member",
    feedback: "Navayuga's support in providing loans through SHGs has been life-changing. With their help, I was able to start my small business, and I am extremely satisfied with the outcomes.",
  },
  {
    image: kar,
    name: "Government of Karnataka",
    position: "State Government",
    feedback: "The Government of Karnataka has played a crucial role in advancing water sanitation, women empowerment, rural development, and health initiatives through our partnership. Their unwavering support has been instrumental in our success.",
  },
];

const TestimonialPage: React.FC = () => {
  return (
    <section id="testimonials" className="bg-gray-50 py-16 px-6">
      <div className="max-w-5xl mx-auto text-center mb-12">
        <motion.h3
          className="text-3xl md:text-4xl font-bold text-gray-800 mb-4"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          What Our Beneficiaries Say
        </motion.h3>
        <motion.p
          className="text-lg md:text-xl text-gray-600"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut', delay: 0.3 }}
        >
          Hear from the people whose lives have been touched by our work.
        </motion.p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut', delay: index * 0.2 }}
          >
            <div className="flex items-center space-x-4 mb-4">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-20 h-20 rounded-full object-cover border-2 border-gray-200"
              />
              <div>
                <h3 className="text-xl font-semibold text-gray-800">{testimonial.name}</h3>
                <p className="text-gray-600 text-sm">{testimonial.position}</p>
              </div>
            </div>
            <p className="text-gray-700 italic">
              "{testimonial.feedback}"
            </p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialPage;
