import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { footerPhoto, homeImage1 } from '../assets/images';
import { useNavigate } from 'react-router-dom';
import { logo } from '../assets/images';

const Footer: React.FC = () => {

  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate("/contact")
  }
  return (
    <footer
      className="bg-gray-800 text-white py-12"
      style={{
        backgroundImage: `url(${footerPhoto})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="container mx-auto px-4">
        <div className="relative rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
            <div>        
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">Navayuga Education And Rural Development (R).</h3>
              <p className="text-gray-900 font-semibold"> KCC Avenue, 2nd Floor, 1st Cross, Chethana Extension, Batawadi Circle, Beside Anjaneya Temple, </p>
              <p className="text-gray-900 font-semibold">Tumkur - 572 103.</p>
            </div>

            {/* Second Column */}
            <div>
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">Follow Us</h3>
              <div className="flex space-x-6">
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-600 transition-colors">
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-400 transition-colors">
                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
                <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="text-black hover:text-red-600 transition-colors">
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-black hover:text-pink-500 transition-colors">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-700 transition-colors">
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
              </div>
            </div>

            {/* Third Column */}
            <div>
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">About Us</h3>
              <p className="text-gray-900">Navayuga Education and Rural Development Society (R) empowers women and drives rural development. We form Self Help Groups (SHGs) for economic independence, improve water access, manage sanitation with UNICEF, and support small farmers with subsidized machinery.

</p>
            </div>

            {/* Fourth Column */}
            <div>
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="#home" className="hover:underline text-gray-900">Home</a></li>
                <li><a href="#about" className="hover:underline text-gray-900">About Us</a></li>
                <li><a href="#programs" className="hover:underline text-gray-900">Our Programs</a></li>
                <li><a className="hover:underline text-gray-900" onClick={handleOnClick}>Contact Us</a></li>
                <li><a href="#getinvolved" className="hover:underline text-gray-900">Get Involved</a></li>
              </ul>
            </div>
          </div>
          <div className="text-center pt-4 mt-8 bg-gray-700 bg-opacity-30 p-6">
            <p className="text-gray-900">© 2024 NAVAYUGA. All rights reserved.</p>
            <p className="text-gray-900">Project managed by <a href="https://poorvadi.com/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">poorvadi.com</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
