import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { diya, Community } from '../assets/images';
import emailjs from 'emailjs-com';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    postalCode: '',
    address: '',
    email: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError(null);

    try {
      const templateParams = {
        from_name: formData.firstName + ' ' + formData.lastName,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        postalCode: formData.postalCode,
        address: formData.address,
        email: formData.email,
      };

      await emailjs.send(
        'service_7nwixk3', // Replace with your EmailJS service ID
        'template_s0htrv7', // Replace with your EmailJS template ID
        templateParams,
        'G-p0XrbZc9fXRODSL' // Replace with your EmailJS user ID
      );

      setSuccess(true);
      setFormData({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        postalCode: '',
        address: '',
        email: '',
      });
    } catch (error) {
      setError('An error occurred while sending the email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="Get Involved" className="py-16 bg-gray-50 relative" style={{ backgroundImage: `url(${Community})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="container mx-auto px-6 relative z-10">
        <h2 className="text-4xl font-extrabold mb-10 text-green-700 text-left">Join Us</h2>

        <div className="flex flex-col md:flex-row justify-between items-stretch space-y-10 md:space-y-0 md:space-x-8">
          
          {/* Left Section - How to Get Involved */}
          <motion.div
            className="md:w-1/2 flex-1 bg-white p-8 rounded-xl shadow-lg flex flex-col"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-2xl font-semibold mb-4 text-gray-700">How to Get Involved</h3>
            <p className="mb-4 text-gray-600">
              We welcome volunteers and supporters to join us in our mission. Whether it's through donations, participating in events, or spreading the word, there are many ways you can help.
            </p>
            <p className="text-lg text-gray-700">
              Together, we form a global movement committed to driving the essential changes our world needs. Sign up to receive updates on our campaigns and discover how you can make a difference.
            </p>
            <div className="flex justify-center mt-6">
              <img
                src={diya}
                alt="Diya"
                className="w-1/2 md:w-1/3 rounded-full shadow-lg"
              />
            </div>
          </motion.div>

          {/* Right Section - Join Form */}
          <motion.div
            className="md:w-1/2 flex-1 bg-white p-8 rounded-xl shadow-lg"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                <div className="w-full md:w-1/2">
                  <label className="block text-sm font-medium text-gray-700">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#FECE00] focus:border-[#FECE00]"
                    placeholder="First Name"
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <label className="block text-sm font-medium text-gray-700">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#FECE00] focus:border-[#FECE00]"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                <div className="w-full md:w-1/2">
                  <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#FECE00] focus:border-[#FECE00]"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <label className="block text-sm font-medium text-gray-700">Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#FECE00] focus:border-[#FECE00]"
                    placeholder="Postal Code"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#FECE00] focus:border-[#FECE00]"
                  placeholder="Address"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#FECE00] focus:border-[#FECE00]"
                  placeholder="Email"
                />
              </div>
              {loading && (
                <p className="text-gray-500">Sending...</p>
              )}
              {error && (
                <p className="text-red-600">{error}</p>
              )}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-3 px-4 rounded-md shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out"
                disabled={loading}
              >
                {loading ? 'Joining...' : 'JOIN'}
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
