import React from 'react';
import { motion } from 'framer-motion';
import { achyuth } from '../assets/images';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const teamMembers = [
  {
    name: 'Achyuth Yadav',
    position: 'Secretary',
    bio: 'Achyuth has over 8 years of experience in leading teams and driving innovation for community development.',
    image: achyuth,
  },
  {
    name: 'manjunath',
    position: '',
    bio: 'manjunath has extensive experience in field operations and community engagement.',
    // image: manjunath,
  },
  {
    name: 'siddu',
    position: '',
    bio: 'siddu specializes in logistical support and team coordination.',
    // image: sidduImage,
  },
];

const Team: React.FC = () => {
  return (
    <section id="team" className="py-16 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold mb-8 text-green-700 text-left">Our Team</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-xl shadow-lg flex flex-col items-center transition-transform transform hover:scale-105"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <img
                src={member.image}
                alt={member.name}
                className="w-32 h-32 rounded-full mb-4 border-4 border-gray-200 shadow-md"
                style={{ objectFit: 'cover' }}
              />
              <h3 className="text-xl font-semibold text-gray-800">{member.name}</h3>
              <p className="text-yellow-700 font-semibold mb-2">{member.position}</p>
              <p className="text-gray-700 text-center">{member.bio}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;