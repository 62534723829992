// src/components/CustomRightArrow.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CustomRightArrow = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-2 rounded-full shadow-lg"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} size="lg" />
    </button>
  );
};

export default CustomRightArrow;
