import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const GetInvolved: React.FC = () => {
  return (
    <section id="getinvolved" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-extrabold mb-8 text-left text-green-700">Get In Touch</h2>
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start bg-white p-8 rounded-lg shadow-lg">
          
          {/* Left Section - Contact Information */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Contact Information</h3>
            <p className="mb-2 text-gray-600"><strong>Address:</strong> KCC Avenue, 2nd Floor, 1st Cross, Chethana Extension, Batawadi Circle, Beside Anjaneya Temple, Tumkur - 572 103.</p>
            <p className="mb-2 text-gray-600"><strong>Phone:</strong> (+91) 8277699488</p>
            <p className="mb-4 text-gray-600"><strong>Email:</strong> contact@navayuga.org.in</p>

            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Office Hours</h3>
            <p className="mb-1 text-gray-600">Monday - Friday: 09:00 AM - 5:00 PM</p>
            <p className="mb-1 text-gray-600">Saturday: 9:00 AM - 2:00 PM</p>
            <p className="mb-4 text-gray-600">Sunday: Closed</p>
            
            {/* Social Media Icons */}
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Follow Us</h3>
            <div className="flex space-x-6 mt-4">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-600 transition"
                aria-label="Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink-500 hover:text-pink-700 transition"
                aria-label="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700 hover:text-blue-900 transition"
                aria-label="LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-red-700 hover:text-red-900 transition"
                aria-label="YouTube"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </div>
          </div>

          {/* Right Section - Map and Location */}
          <div className="md:w-1/2 md:ml-6">
            <p className="text-2xl font-semibold text-gray-800 mb-4">Our Location</p>
            <p className="text-sm text-gray-600 mb-6">
              Navayuga Education And Rural Development Society (R), Tumkur - 572 103.
            </p>
            <div className="relative rounded-lg overflow-hidden shadow-lg">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4595.051236754264!2d77.12910432571879!3d13.33238290686446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb02c420e38a5bd%3A0x228b75483b5674ad!2sBatawadi%2C%20Tumakuru%2C%20Karnataka%20572103%2C%20India!5e1!3m2!1sen!2sau!4v1723483774410!5m2!1sen!2sau"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInvolved;
