import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaSeedling, FaHandsHelping, FaGraduationCap, FaHeartbeat, FaWater, FaLeaf } from 'react-icons/fa';

const AboutDetails: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section id="about-details" className="py-28 bg-gradient-to-r from-green-200 to-blue-200">
            <div className="container mx-auto px-6 lg:px-12">
                <motion.div
                    className="text-center"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <h2 className="text-5xl font-bold mb-6 text-green-800">More About Us</h2>
                    <p className="mb-8 text-xl text-gray-800">
                        Delve deeper into our mission to uplift communities. Explore our impactful initiatives and see how we are making a difference across various sectors.
                    </p>
                </motion.div>

                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-12"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    {/* Women Empowerment */}
                    <div className="bg-white p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                        <FaHandsHelping className="text-4xl text-green-600 mb-4" />
                        <h3 className="text-2xl font-semibold mb-4 text-green-700">Women Empowerment</h3>
                        <p className="text-lg text-gray-600">
                            Our initiatives provide women with the necessary skills and resources to achieve financial independence and leadership in their communities.
                        </p>
                    </div>

                    {/* Water, Sanitation, and Hygiene */}
                    <div className="bg-white p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                        <FaWater className="text-4xl text-blue-600 mb-4" />
                        <h3 className="text-2xl font-semibold mb-4 text-blue-700">Water, Sanitation, and Hygiene</h3>
                        <p className="text-lg text-gray-600">
                            We ensure access to clean water, sanitation facilities, and essential hygiene practices to promote health and prevent diseases.
                        </p>
                    </div>

                    {/* Agriculture Development */}
                    <div className="bg-white p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                        <FaLeaf className="text-4xl text-green-600 mb-4" />
                        <h3 className="text-2xl font-semibold mb-4 text-green-700">Agriculture Development</h3>
                        <p className="text-lg text-gray-600">
                            Empowering farmers with modern tools, training, and sustainable practices to enhance productivity and sustainability.
                        </p>
                    </div>

                    {/* Health Care */}
                    <div className="bg-white p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                        <FaHeartbeat className="text-4xl text-red-600 mb-4" />
                        <h3 className="text-2xl font-semibold mb-4 text-red-700">Health Care</h3>
                        <p className="text-lg text-gray-600">
                            Improving healthcare accessibility and quality through education, infrastructure, and community-based services.
                        </p>
                    </div>

                    {/* Education Development */}
                    <div className="bg-white p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                        <FaGraduationCap className="text-4xl text-yellow-600 mb-4" />
                        <h3 className="text-2xl font-semibold mb-4 text-yellow-700">Education Development</h3>
                        <p className="text-lg text-gray-600">
                            Enhancing education quality and accessibility to foster lifelong learning and empower individuals through knowledge.
                        </p>
                    </div>

                    {/* Environment Conservation */}
                    <div className="bg-white p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                        <FaSeedling className="text-4xl text-green-500 mb-4" />
                        <h3 className="text-2xl font-semibold mb-4 text-green-600">Environment Conservation</h3>
                        <p className="text-lg text-gray-600">
                            Engaging in conservation efforts to protect natural resources and promote sustainable practices within communities.
                        </p>
                    </div>
                </motion.div>

                <motion.div
                    className="text-center mt-16"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                >
                    <p className="text-lg text-gray-800">
                        Join us on our journey to make a lasting impact. Together, we can create a brighter future for all.
                    </p>
                </motion.div>
            </div>
        </section>
    );
};

export default AboutDetails;
