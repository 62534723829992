import React from 'react';
import { motion } from 'framer-motion';
import { mission1, vision1, value1, goal } from '../assets/images'; // Assuming 'goals' image is available

const VisionMissionValues: React.FC = () => {
  return (
    <section id="vision-mission-values" className="py-20 bg-gray-50 relative overflow-hidden">
      <div className="container mx-auto px-6 relative z-10">
        {/* Section Heading */}
        <motion.h1
          className="text-4xl font-bold mb-12 text-left text-green-700"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Vision, Mission, and Values
        </motion.h1>

        <div className="flex flex-col lg:flex-row flex-wrap items-stretch justify-center space-y-12 lg:space-y-0 lg:space-x-10">
          
          {/* Mission Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start text-center lg:text-left transition-transform hover:scale-105">
            <div className="bg-gradient-to-r from-blue-100 to-blue-300 p-10 rounded-lg shadow-lg border border-blue-200 hover:shadow-xl transition-shadow w-full max-w-md h-full flex flex-col">
              <div className="flex-1 flex flex-col justify-between">
                <motion.div
                  className="mb-6 flex justify-center"
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={mission1}
                    alt="Mission"
                    className="rounded-full border-4 border-white shadow-md w-32 h-32 object-cover"
                  />
                </motion.div>
                <h2 className="text-2xl font-bold mb-4 text-gray-600">Our Mission</h2>
                <p className="text-base leading-relaxed text-gray-700">
                  Our mission is to drive transformative change in rural communities by empowering marginalized populations, especially women, with the tools, resources, and knowledge they need to achieve economic independence, enhance their health, and improve their overall quality of life. We are dedicated to tackling critical issues through innovative and community-driven solutions in key areas such as women’s empowerment, access to clean drinking water, reliable sanitation, sustainable agriculture, healthcare improvements, and educational advancement. By integrating these efforts, we strive to build resilient and thriving communities that are equipped to face challenges and embrace opportunities for growth and development.
                </p>
              </div>
            </div>
          </div>
          
          {/* Vision Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start text-center lg:text-left transition-transform hover:scale-105">
            <div className="bg-gradient-to-r from-green-100 to-green-300 p-10 rounded-lg shadow-lg border border-green-200 hover:shadow-xl transition-shadow w-full max-w-md h-full flex flex-col">
              <div className="flex-1 flex flex-col justify-between">
                <motion.div
                  className="mb-6 flex justify-center"
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={vision1}
                    alt="Vision"
                    className="rounded-full border-4 border-white shadow-md w-32 h-32 object-cover"
                  />
                </motion.div>
                <h2 className="text-2xl font-bold mb-4 text-gray-600">Our Vision</h2>
                <p className="text-base leading-relaxed text-gray-700">
                  We envision a future where rural communities are vibrant, self-sustaining, and inclusive, with every individual empowered to reach their full potential. Our vision includes a world where women lead with confidence and economic independence, where clean water and sanitation are universally accessible, and where quality healthcare and education are available to all. We see communities actively engaged in environmental stewardship, promoting sustainable practices, and ensuring the protection and regeneration of natural resources. Through collaboration and dedication, we aim to contribute to a just and equitable world where every person has the opportunity to thrive and make a positive impact.
                </p>
              </div>
            </div>
          </div>

          {/* Values Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start text-center lg:text-left transition-transform hover:scale-105">
            <div className="bg-gradient-to-r from-purple-100 to-purple-300 p-10 rounded-lg shadow-lg border border-purple-200 hover:shadow-xl transition-shadow w-full max-w-md h-full flex flex-col">
              <div className="flex-1 flex flex-col justify-between">
                <motion.div
                  className="mb-6 flex justify-center"
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={value1}
                    alt="Values"
                    className="rounded-full border-4 border-white shadow-md w-32 h-32 object-cover"
                  />
                </motion.div>
                <h2 className="text-2xl font-bold mb-4 text-gray-600">Our Values</h2>
                <ul className="list-disc pl-6 md:pl-0 space-y-4 text-base leading-relaxed text-gray-700">
                  <li><span className="font-semibold">Empowerment:</span> We believe in empowering individuals and communities to take control of their futures and create positive change.</li>
                  <li><span className="font-semibold">Sustainability:</span> We are dedicated to promoting practices that ensure long-term environmental, economic, and social sustainability.</li>
                  <li><span className="font-semibold">Equality:</span> We strive to create equal opportunities for all, particularly for women and marginalized groups.</li>
                  <li><span className="font-semibold">Integrity:</span> We operate with transparency, accountability, and a commitment to ethical practices in all our endeavors.</li>
                  <li><span className="font-semibold">Collaboration:</span> We value partnerships and teamwork, recognizing that collective efforts lead to greater impact.</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Goals Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start text-center lg:text-left transition-transform hover:scale-105">
            <div className="bg-gradient-to-r from-teal-100 to-teal-300 p-10 rounded-lg shadow-lg border border-teal-200 hover:shadow-xl transition-shadow w-full max-w-md h-full flex flex-col">
              <div className="flex-1 flex flex-col justify-between">
                <motion.div
                  className="mb-6 flex justify-center"
                  initial={{ opacity: 0, y: -50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <img
                    src={goal}
                    alt="Goals"
                    className="rounded-full border-4 border-white shadow-md w-32 h-32 object-cover"
                  />
                </motion.div>
                <h2 className="text-2xl font-bold mb-4 text-gray-600">Our Goals</h2>
                <ul className="list-disc pl-6 md:pl-0 space-y-4 text-base leading-relaxed text-gray-700">
                  <li><span className="font-semibold">Empowering Women:</span> Creating self-help groups to foster financial independence and leadership.</li>
                  <li><span className="font-semibold">Improving Community Health:</span> Enhancing access to clean water, sanitation, and healthcare in rural areas.</li>
                  <li><span className="font-semibold">Agricultural Development:</span> Supporting farmers with resources and sustainable practices to boost productivity.</li>
                  <li><span className="font-semibold">Educational Advancement:</span> Promoting quality education and effective learning environments in rural schools.</li>
                  <li><span className="font-semibold">Environmental Protection:</span> Advocating for climate justice and responsible management of natural resources.</li>
                  <li><span className="font-semibold">Strengthening Local Economies:</span></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default VisionMissionValues;
