// src/pages/Home.tsx
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { motion } from 'framer-motion';
import { home1, homeImage1, homeImage2, homeImage3, homeImage4, waterSanitization, waterSanitization1 } from '../assets/images';

const Home: React.FC = () => {
  const slides = [
    {
      image: home1,
      title: "Navayuga Education and Rural Development Society (R)",
      description: "Empowering Progress, Enriching Lives",
      imageAlt: "Navayuga Society",
    },
    {
      image: homeImage2,
      title: "Empowering Women, Building Futures: Transforming Lives Through Self Help Groups (SHGs)",
      description: "Strengthening Communities One Group at a Time.",
      buttonLabel: "Discover",
      imageAlt: "Innovation",
    },
    {
      image: waterSanitization1,
      title: "Empowering Communities, Protecting Lives: Clean Water and Hygiene for All",
      description: "Clean Water, Safe Lives",
      buttonLabel: "Explore",
      imageAlt: "Sustainability",
    }
  ];

  return (
    <section id="home" className="relativeh-screen md:h-1/2 lg:h-screen overflow-hidden bg-gray-900">
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        interval={3000}
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <li
            className={`inline-block h-1 w-8 mx-1 cursor-pointer rounded-full transition-all duration-300 ${
              isSelected ? 'bg-white' : 'bg-gray-500'
            }`}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          />
        )}
        className="relative"
      >
        {slides.map((slide, index) => (
          <div key={index} className="relative flex items-center justify-center">
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              className="object-cover h-full w-full"
            />
            <motion.div
              className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center p-6 space-y-4 text-center"
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-extrabold uppercase tracking-wide">
                {slide.title}
              </h2>
              <p className="text-base md:text-lg lg:text-xl xl:text-2xl text-gray-300 max-w-4xl">
                {slide.description}
              </p>
              {/* <button className="bg-[#FECE00] hover:bg-yellow-400 text-black px-4 py-2 md:px-6 md:py-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                {slide.buttonLabel}
              </button> */}
            </motion.div>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default Home;