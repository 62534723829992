// src/pages/ProgramDetails.tsx
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Community, Education, Empowerment, HealthCare, RuralDevelopment } from '../assets/images';

const programs = [
  {
    id: 1,
    image: RuralDevelopment,
    title: 'Rural Development Initiative',
    description: 'Empowering rural communities with sustainable projects.',
    details: 'Our Rural Development Initiative focuses on creating sustainable development projects in rural areas. We aim to improve infrastructure, provide vocational training, and support local entrepreneurship to uplift these communities. This initiative includes building roads, providing clean water, and establishing educational centers.'
  },
  {
    id: 2,
    image: Empowerment,
    heading: "Women Empowerment",
    title: 'Empowering Women for Economic Independence',
    description: 'Enhancing women’s control over resources and income through Self Help Groups.',
    details: 'We are committed to the economic empowerment of women, helping them gain control over resources, assets, and income. Through our initiatives, we enhance their ability to manage risks and improve their overall well-being. By establishing Self Help Groups (SHGs), we bring together women who face similar challenges, fostering mutual support and encouraging financial independence through small savings.'
  },
  {
    id: 3,
    image: Education,
    title: 'Educational Outreach',
    description: 'Providing quality education to underprivileged children.',
    details: 'Our Educational Outreach program focuses on providing quality education to underprivileged children. We establish schools, provide scholarships, and offer tutoring services to ensure that every child has the opportunity to receive an education. We believe that education is the key to breaking the cycle of poverty and creating a brighter future.'
  },
  {
    id: 4,
    image: HealthCare,
    title: 'Healthcare Access Program',
    description: 'Improving healthcare facilities in rural areas.',
    details: 'The Healthcare Access Program aims to enhance healthcare facilities in rural areas by setting up clinics, providing medical supplies, and training healthcare professionals. Our mission is to ensure that everyone, regardless of their location, has access to essential healthcare services. We also focus on preventive care and health education to improve overall community health.'
  },
  {
    id: 5,
    image: Community,
    title: 'Community Engagement',
    description: 'Fostering community involvement in development projects.',
    details: 'The Community Engagement program seeks to foster active participation in development projects. We organize community meetings, create volunteer opportunities, and encourage local residents to take part in the decision-making process. By involving the community, we ensure that development projects are tailored to their needs and have a lasting impact.'
  }
];

const ProgramDetails: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams<{ id: string }>();

  const program = programs.find((p) => p.id === parseInt(id || '', 10));

  if (!program) {
    return (
      <section className="py-12 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4 text-red-600">Program Not Found</h2>
          <p className="text-lg text-gray-700">Sorry, we couldn't find the program you're looking for.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <img src={program.image} alt={program.title} className="w-full h-72 object-cover" />
          <div className="p-6">
            <h2 className="text-3xl font-semibold mb-4 text-green-800">{program.title}</h2>
            <p className="text-lg mb-4 text-gray-600">{program.description}</p>
            <p className="text-base text-gray-700">{program.details}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgramDetails;
