// src/pages/Programs.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../styles/custom-carousel.css';
import CustomLeftArrow from '../components/CustomLeftArrow';
import CustomRightArrow from '../components/CustomRightArrow';
import { Community, Education, Empowerment, environment, HealthCare, RuralDevelopment, waterSanitization1 } from '../assets/images';

const programs = [
  {
    id: 1,
    image: RuralDevelopment,
    heading: "Women Empowerment",
    description: 'Enhancing women’s control over resources and income through Self Help Groups.'
  },
  {
    id: 2,
    image: waterSanitization1,
    heading: "Water, Sanitation, and Hygiene",
    description: 'Supporting small and marginal farmers with subsidized farm machinery to enhance productivity and sustainability.'
  },
  {
    id: 3,
    image: HealthCare,
    heading: "Promoting Healthy Lifestyles.",
    description: 'Improving healthcare facilities and spreading health awareness in rural areas.'
  },
  {
    id: 4,
    image: Education,
    heading: 'Fostering Educational Excellence',
    description: 'Enhancing educational institutions to create vibrant and effective learning environments.'
  },
  {
    id: 5,
    image: environment,
    heading: "Environment Conservation",
    description: 'Partnering with communities for regenerative agriculture, reforestation, and climate justice.'
  },
  {
    id: 6,
    image: Community,
    heading: 'Community Engagement',
    description: 'Fostering community involvement and participation in developmental projects.'
  }
];

const Programs: React.FC = () => {
  const navigate = useNavigate();

  const handleProgramClick = (id: number) => {
    navigate(`/programs/${id}`);
  };

  return (
    <section id="programs" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4 relative">
        <h2 className="text-4xl font-bold mb-8 text-green-700">Our Programs</h2>
        <Carousel
          responsive={{
            superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 4 },
            desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
            tablet: { breakpoint: { max: 768, min: 464 }, items: 2 },
            mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
          }}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          containerClass="custom-dots"
        >
          {programs.map((program) => (
            <motion.div
              key={program.id}
              className="bg-white shadow-md rounded-lg overflow-hidden mx-2 cursor-pointer h-[500px] flex flex-col justify-between" // Increased card height and added flexbox layout
              whileHover={{ scale: 1.05 }}
              onClick={() => handleProgramClick(program.id)}
            >
              <img src={program.image} alt={program.heading} className="w-full h-64 object-cover" /> {/* Increased image height */}
              <div className="p-4 flex flex-col justify-between flex-grow">
                <div>
                  <h3 className="text-xl font-bold">{program.heading}</h3>
                  <p className="mt-2">{program.description}</p>
                </div>
                <button className="mt-4 bg-yellow-200 text-black py-2 px-4 rounded hover:bg-green-600 self-start">
                  Read More
                </button>
              </div>
            </motion.div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Programs;